$color-button: #53ac5d;
$color-hover: #75d780;
$margin-button: 0.2rem;

.height-of-card {
  width: 300px;
  height: 360px;
}
.form-menu {
  background-color: $color-button;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.35rem;
  border-radius: 0.5rem;
  border: none;
  margin-left: $margin-button;
  margin-right: $margin-button;
  cursor: pointer;
  &:hover {
    background-color: $color-hover;
    border-color: $color-hover;
    color: #fff;
    transform: scale(1.1);
  }
  &:focus {
    outline: none;
  }
}

.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.list-positions {
  list-style: outside; /* Ensures bullets appear outside the list item */
  display: flex; /* Enables flexbox layout */
  flex-direction: column; /* Aligns children vertically */
  align-items: start; /* Aligns children in the center */
  text-align: left; /* Ensures text alignment is kept to the left */
  text-decoration: none;
  padding-left: 25%; /* Creates space between bullet and text */
}

.list-positions li {
  width: fit-content; /* Ensures the width fits the content */
  padding-top: 0.75rem;
}

.recruitment-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
}
// Put button at the bottom of the element
.button-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 1rem;
  font-weight: 700;
}
