.contact-special {
    text-align: left;
    .mail {
      font-size: clamp(16px, 1.4vw, 1.4rem);
      padding: 0;
    }
    p {
      font-weight: 700;
    }
  
    a {
      font-size: 20px;
      font-style: normal;
    }
  }

.invitation-img {
    width: 100%; 
    max-width: 450px; 
    max-height: 600px; 
    overflow-y: auto; 
    border: 0;
}

.invitation {
    width: 100%; 
    // max-width: 900px; 
    min-height: 600px; 
    overflow-y: auto; 
    border: 0;
}

.banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    border: 0;
}

.aux-p {
    font-size: 20px;
}
