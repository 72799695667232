.marquee {
  overflow: hidden;
  position: fixed;
  height: 4rem;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(255, 255, 255, 0.662);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.717);
  z-index: 1 !important;
  .marquee__text {
    position: absolute;
    display: inline-block;
    // margin: 1rem;
    white-space: nowrap;
    animation: marquee 200s linear infinite;
    font-weight: 800;
  }

.bottom-scroll {
    color: #000000;
    text-decoration: none;
  }

  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

.spacer {
  margin-right: 3rem;
}
